<template>
  <div>
    <v-row>
      <v-col cols="2" class="">
        <associatedOption
          :fieldAttributes="{
            name: 'IP Version',
            associatedOption: localCodes,
          }"
          type="select"
          :fieldAttrInput="{
            class: 'required',
            id: result.contentID + '_ipVersion',
          }"
          v-model="ipVersion"
          :templateContent="result"
          :isValid="isValid"
        ></associatedOption>
      </v-col>
      <v-col cols="7">
        <!-- <primaryTextfield
          type="text"
          :fieldAttrInput="{
            class: inputClass,
            id: result.contentID + '_host',
          }"
          label="Host"
          v-model="host"
          :templateContent="result"
          :isValid="isValid"
        ></primaryTextfield> -->
        <ip4ip6ValidatedInput
          field="host"
          :fieldAttributes="{name:'Host'}"
          v-model="host"
          :valueLine="this.localStoredStep.parameters.target"
          :result="result"
          :isValid="isValid"
          :required="this.localStoredStep.parameters.target.type == 'static'"
        >
        </ip4ip6ValidatedInput>
      </v-col>
      <v-col cols="3">
        <primaryTextfield
          type="text"
          :fieldAttrInput="{ class: ' number', id: result.contentID + '_port' }"
          label="Port"
          v-model="port"
          :templateContent="result"
          :isValid="isValid"
        ></primaryTextfield>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
import associatedOption from "@/commonComponents/associatedOption.vue";
import ip4ip6ValidatedInput from "@/components/dynamic/ip4ip6ValidatedInput.vue";

export default {
  props: ["localStoredStep", "result", "isValid"],
  data: function () {
    return {
      localCodes: ["ipv4", "ipv6"],
    };
  },
  components: {
    primaryTextfield,
    associatedOption,
    ip4ip6ValidatedInput,
  },
  // watch: {
  //   ipVersion() {
  //     let tmp = this.host;
  //     this.host = "";
  //     this.$nextTick(function () {
  //       this.host = tmp;
  //     });
  //   },
  // },
  computed: {
    // inputClass() {
    //   let ic = "required";
    //   if (this.ipVersion == "ipv4") {
    //     ic += " IPaDomainaVariable";
    //   } else if (this.ipVersion == "ipv6") {
    //     ic += " IP6aDomainaVariable";
    //   }
    //   return ic;
    // },
    ipVersion: {
      get() {
        //console.log(this.valueLine);
        let ipVersion = "ipv4";
        if (typeof this.localStoredStep.parameters.target.ip != "undefined") {
          ipVersion = this.localStoredStep.parameters.target.ip;
        }
        return ipVersion;
      },
      set(selected) {
        console.log(selected);
        this.$set(this.localStoredStep.parameters.target, "ip", selected);
      },
    },
    evalValue: {
      get() {
        let evalValue = {};
        // console.log(this.localStoredStep);
        if (
          typeof this.localStoredStep["parameters"] != "undefined" &&
          typeof this.localStoredStep["parameters"]["target"] != "undefined"
        ) {
          evalValue = this.localStoredStep["parameters"]["target"];
        }
        return evalValue;
      },
      set(newVal) {
        this.updateResponseKey("target", newVal);
      },
    },
    host: {
      get() {
        let host = "";
        if (typeof this.evalValue["host"] != "undefined") {
          host = this.evalValue["host"];
        } else if (
          typeof this.localStoredStep["parameters"].host != "undefined"
        ) {
          host = this.localStoredStep["parameters"].host;
        }
        return host;
      },
      set(newVal) {
        //console.log("partne",newVal)
        // if (typeof this.evalValue.resourceTag != "undefined") {
        //   this.$set(this.localStoredStep["parameters"], "host", newVal);
        // } else {
          this.$set(this.evalValue, "host", newVal);
        // }
          this.updateResponseKey("target", this.evalValue);
      },
    },
    port: {
      get() {
        let port = "";
        if (typeof this.evalValue["port"] != "undefined") {
          port = this.evalValue["port"];
        } else if (
          typeof this.localStoredStep["parameters"].port != "undefined"
        ) {
          port = this.localStoredStep["parameters"].port;
        }
        return port;
      },
      set(newVal) {
        // if (typeof this.evalValue.resourceTag != "undefined") {
        //   this.$set(this.localStoredStep["parameters"], "port", newVal);
        // } else {
          this.$set(this.evalValue, "port", newVal);
          this.updateResponseKey("target", this.evalValue);
        // }
      },
    },
  },
  methods: {
    updateResponseKey(index, newVal) {
      // console.log("STAIC", index, newVal);
      // if (typeof this.evalValue.resourceType != "undefined") {
        this.$set(this.localStoredStep["parameters"], index, { ...newVal });
      
      // } else {
      //   this.$set(this.localStoredStep["parameters"], index, {
      //     ...newVal,
      //     type: "static",
      //   });
      // }
      this.$emit("stored-value-changed", this.localStoredStep);
    },
  },
};
</script>