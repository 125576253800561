<template>
  <tabsWithoutValidation
    :tabNames="tabNames"
    tabsPrefix="speedTest"
    v-on="$listeners"
    @tab-changed="changeTargetType"
    :tab="tab"
  >
    <template v-slot:tabsContent="data">
      <slot name="tabsContent" :item="data.item"></slot>
    </template>
  </tabsWithoutValidation>
</template>
<script>
import tabsWithoutValidation from "@/components/legacy/tabsWithoutValidation.vue";
export default {
  components: { tabsWithoutValidation },
  computed: {
    tabNames() {
      let tabNames = ["static", "resource"];
      return tabNames;
    },
    tab() {
      return this.tabNames.indexOf(this.type);
    },
  },
  props: ["isValid", "type"],
  methods: {
    changeTargetType(value) {
      this.$emit("type-changed", this.tabNames[value]);
       //  this.$emit("revalidate");
      // this.$emit("changeIsValid", true);
            this.$emit("changeIsValid", false);
       this.$nextTick(function() {
        this.$emit("revalidate");
       });
    },
  },
};
</script>