var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('associatedOption',{attrs:{"fieldAttributes":{
          name: 'IP Version',
          associatedOption: _vm.localCodes,
        },"type":"select","fieldAttrInput":{
          class: 'required',
          id: _vm.result.contentID + '_ipVersion',
        },"templateContent":_vm.result,"isValid":_vm.isValid},model:{value:(_vm.ipVersion),callback:function ($$v) {_vm.ipVersion=$$v},expression:"ipVersion"}})],1),_c('v-col',{attrs:{"cols":"7"}},[_c('ip4ip6ValidatedInput',{attrs:{"field":"host","fieldAttributes":{name:'Host'},"valueLine":this.localStoredStep.parameters.target,"result":_vm.result,"isValid":_vm.isValid,"required":this.localStoredStep.parameters.target.type == 'static'},model:{value:(_vm.host),callback:function ($$v) {_vm.host=$$v},expression:"host"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('primaryTextfield',{attrs:{"type":"text","fieldAttrInput":{ class: ' number', id: _vm.result.contentID + '_port' },"label":"Port","templateContent":_vm.result,"isValid":_vm.isValid},model:{value:(_vm.port),callback:function ($$v) {_vm.port=$$v},expression:"port"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }