<template>
    <div>
         <staticResourceTabs
                :isValid="isValid"
                v-on="$listeners"
                :type="valueLine.parameters.target.type"
        >
           <template v-slot:tabsContent="data">
                <div class="mb-n9" v-if="data.item == 'static'">
                    <speedTestStaticContent
                            :localStoredStep="valueLine"
                            v-on="$listeners"
                            :result="result"
                            :isValid="isValid"
                            :hideDetails="false"
                    ></speedTestStaticContent>
                </div>
                <div class="mb-n9" v-if="data.item == 'resource'">
                     <speedTestResourceContent
                            :localStoredStep="valueLine"
                            v-on="$listeners"
                            :result="result"
                            :isValid="isValid"
                            :hideDetails="false"
                    ></speedTestResourceContent>
                </div>
            </template>
        </staticResourceTabs>
        <div>
            <fieldsRows
                    :templateContent="templateContent"
                    @field-value-changed="updateFieldValue(...arguments)"
                    :valueLine="valueLine['parameters']"
                    :isValid="isValid"
                    @revalidate="$emit('revalidate')"
                    

            ></fieldsRows>
        </div>
    </div>
</template>
<script>
  import staticResourceTabs from "@/components/legacy/staticResourceTabs.vue";
  import speedTestStaticContent from "@/components/legacy/speedTestStaticContent.vue";
  import speedTestResourceContent from "@/components/legacy/speedTestResourceContent.vue";
  import fieldsRows from "@/commonComponents/fieldsRows.vue";

  export default {
    data() {
      return {
        //attrSource: "t_testcases",
        //cachedParametersAttributes: []
      };
    },
    components: {
      staticResourceTabs,
      speedTestStaticContent,
      speedTestResourceContent,
      fieldsRows
    },
    props: {
       result:{},
       field: String,
       isValid: Boolean,
       valueLine: {},
       value: {},
    },
    methods: {
      updateFieldValue(index, value) {
        console.log("--->",index,value);
        if(index!="testDirection"){
          this.$set(this.valueLine['parameters'], index, value);
          this.$emit("stored-value-changed", this.valueLine);
        }
      },
    },
    computed: {
      templateContent() {
        let templateContent = {
          fields: {
            testDirection: {
              inputFieldFormatter: "testDirectionDurationIP",
            },
           /* congestionHighTimeLine: {
              type: "line",
              fields: {
                lowDelayVar: {
                  type: "number",
                  fieldAttrInput: { class: "" },
                  colAttrs: { class: "" },
                  name: "Low delay variation threshold in ms",
                },
                upperDelayVar: {
                  type: "number",
                  fieldAttrInput: { class: "" },
                  colAttrs: { class: "" },
                  name: "Upper delay variation threshold in ms",
                },
                c: {
                  type: "number",
                  fieldAttrInput: { class: "" },
                  colAttrs: { class: "" },
                  name: "Congestion slow adjustment threshold",
                },
                h: {
                  type: "number",
                  fieldAttrInput: { class: "" },
                  colAttrs: { class: "" },
                  name: "High speed(row adjustment) delta",
                },
              },
            },*/
          },
          stepType: this.stepType,
          stepID: this.stepID,
          disableAllInputs: this.result.disableAllInputs,
          enableAdd: false,
          contentID: this.result.contentID,
        };
        return templateContent;
      },
    },
  };
</script>