<template>
  <v-card>
    <v-tabs v-model="tabIndex" background-color="primary" dark>
      <v-tab
        v-for="item in tabNames"
        :key="item"
        :id="tabsPrefix + '_tab_' + item"
        >{{ item }}</v-tab
      >
    </v-tabs>
    <v-tabs-items v-model="tabIndex">
      <v-tab-item v-for="item in tabNames" :key="item">
        <v-card flat :id="tabsPrefix + '_body_' + item">
          <v-card-text>
            <slot name="tabsContent" :item="item"></slot>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
export default {
  data() {
    return { currentTab: 0 };
  },
  computed: {
    tabIndex: {
      get() {
        return this.tab;
      },
      set(newVal) {
        this.currentTab = newVal;
        this.$emit("tab-changed", newVal);
      },
    },
  },
  props: ["tabNames", "tabsPrefix", "tab"],
};
</script>